import {gql} from '@apollo/client'

export const GET_PATIENT_LIST_PAGINATION = gql`
  query getBusinessPatientPaginationList($page: Int!, $limit: Int!) {
    getBusinessPatientPaginationList(listFilter: {page: $page, limit: $limit}) {
      data {
        id
        mobileNumber
        firstName
        lastName
        userRoleId
        gender
        userAddress {
          city
        }
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`
export const GET_ACTIVE_BUSINESS = gql`
query{
  getBusinessInActiveProductOffering{
    id
    productName
    productValue
    isActive
    appointmentDurationTime
    appointmentDurationUnit
  }
}
`;
export const UPDATE_PRODUCT_SLOT = gql`
  mutation updateProductSlots($ProductSlotsInput: [ProductSlots!]) {
    updateProductSlots(ProductSlotsInput: $ProductSlotsInput) 
  }
`

export const ADD_PATIENT = gql`
  mutation importPatients(
    $firstName: String!
    $lastName: String!
    $countryCode: Int!
    $mobileNumber: String!
    $gender: GENDER
    $zipCode: String
    $city: String
    $district: String
    $state: String
    $country: String
    $address: String
  ) {
    importPatients(
      patientInput: {
        firstName: $firstName
        lastName: $lastName
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        gender: $gender
        zipCode: $zipCode
        city: $city
        district: $district
        state: $state
        country: $country
        address:$address
      }
    )
  }
`

export const ADD_PATIENT_USING_EXCEL = gql`
  mutation importPatients($doctorId: String, $patientInputs: [patientInput!]!) {
    importPatients(doctorId: $doctorId, patientInput: $patientInputs)
  }
`

export const DELETE_PATIENT = gql`
  mutation deleteBusinessPatient($userRoleId: ID!) {
    deleteBusinessPatient(userRoleId: $userRoleId)
  }
`
export const DELETE_VENDOR = gql`
  mutation deleteVendor($id: ID!) {
    deleteVendor(id: $id)
  }
`
export const DELETE_INVENTORY = gql`
  mutation deleteInventoryProduct($itemInput: itemInput!
  ) {
    deleteInventoryProduct(itemInput: $itemInput
    )
  }
`

export const GET_PATIENT_BY_ID = gql`
  query getBusinessUserProfile($id: ID!) {
    getBusinessUserProfile(userRoleId: $id) {
      userProfile {
        firstName
        lastName
        gender
        email
      }
      userAddress {
        zipCode
        street
        city
        district
        state
        country
      }
      mobileNumber
      countryCode
      __typename
    }
  }
`
export const GET_Vendor_BY_ID = gql`
  query getVendorProfileById($id: ID!) {
    getVendorProfileById(id: $id) {
      id
      mobileNumber
      name
      firmName
      email
      registrationNo
      gstNumber
      dlNumber
      phoneNumber
      pan
      address
      city
      flNumber
      state
      country
      zip
      district
      isActive
      createdBy
      updatedBy
    }
  }
`
export const GET_MEDICINE_BY_ID = gql`
  query getProductByInventoryId($id:ID!) {
    getProductByInventoryId(id: $id) {
      productId
      productName
      mrp
      remainingQuantity
      batchNumber
      purchaseReturnQuantity
      discount
      cgst
      sgst
      rate
      saleReturnQuantity
      saleQuantity
      purchaseReturnQuantity
      purchaseQuantity
      profit
      id
      expriryDate
      manufacutureDate
      manufacturer
      pack
      packUnit
      discountInPrecentage
      isIndividualDiscountActive
      generic
      removeQuantity
      freeQuantity
      quantity
      pack
      hsnCode
    }
  }
`;

export const EDIT_PATIENT = gql`
  mutation editVendorProfile(
    $userRoleId: ID!
    $firstName: String!
    $lastName: String!
    $countryCode: Int!
    $mobileNumber: String!
    $gender: GENDER
    $zipCode: String
    $city: String
    $district: String
    $state: String
    $country: String
    $address:String
  ) {
    editVendorProfile(
      userRoleId: $userRoleId
      patientInput: {
        firstName: $firstName
        lastName: $lastName
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        gender: $gender
        zipCode: $zipCode
        city: $city
        district: $district
        state: $state
        country: $country
        address: $address
      }
    )
  }
`
export const EDIT_VENDOR_PROFILE = gql`
  mutation editVendorProfile(
    $id: ID!
    $vendorInput: vendorProfileInput!
  ) {
    editVendorProfile(
      id: $id
    vendorInput: $vendorInput
    ){
      id
      mobileNumber
      name
      firmName
      email
      registrationNo
      gstNumber
      dlNumber
      phoneNumber
      pan
      address
      city
      flNumber
      state
      country
      zip
      district
      isActive
      createdBy
      updatedBy
    }
  }
`
export const GET_DOCTOR_LIST = gql`
  query getBusinessStaffList($role: String!) {
    getBusinessStaffList(role: $role) {
      userId
      userRoleId
      firstName
      lastName
      role
      profilePhoto
      isRegistered
      updatedDate
    }
  }
`
