import {gql} from '@apollo/client'

export const GET_PATIENT_LIST_PAGINATION = gql`
  query getBusinessPatientPaginationList(
    $page: Int!
    $limit: Int!
    $field: String
    $value: String
  ) {
    getBusinessPatientPaginationList(
      listFilter: {page: $page, limit: $limit}
      queryFilter: {field: $field, value: $value}
    ) {
      data {
        id
        mobileNumber
        firstName
        lastName
        userRoleId
        gender
        userAddress {
          city
          __typename
        }
        __typename
      }
      total
      pageSize
      page
      totalPages
      __typename
    }
  }
`

export const ADD_PATIENT = gql`
  mutation importPatients(
    $firstName: String!
    $lastName: String!
    $countryCode: Int!
    $mobileNumber: String!
    $gender: GENDER
    $address: String
    $zipCode: String
    $city: String
    $district: String
    $state: String
    $country: String
  ) {
    importPatients(
      patientInput: {
        firstName: $firstName
        lastName: $lastName
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        gender: $gender
        zipCode: $zipCode
        city: $city
        district: $district
        state: $state
        country: $country
        address: $address
      }
    )
  }
`

export const ADD_PATIENT_USING_EXCEL = gql`
  mutation importPatients($doctorId: String, $patientInputs: [patientInput!]!) {
    importPatients(doctorId: $doctorId, patientInput: $patientInputs)
  }
`

export const DELETE_PATIENT = gql`
  mutation deleteBusinessPatient($userRoleId: ID!) {
    deleteBusinessPatient(userRoleId: $userRoleId)
  }
`

export const GET_PATIENT_BY_ID = gql`
  query getBusinessUserProfile($id: ID!) {
    getBusinessUserProfile(userRoleId: $id) {
      userProfile {
        firstName
        lastName
        gender
        email
      }
      userAddress {
        zipCode
        street
        city
        district
        state
        country
      }
      mobileNumber
      countryCode
      __typename
    }
  }
`

export const EDIT_PATIENT = gql`
  mutation editBusinessPatient(
    $userRoleId: ID!
    $firstName: String!
    $lastName: String!
    $countryCode: Int!
    $mobileNumber: String!
    $gender: GENDER
    $zipCode: String
    $city: String
    $district: String
    $state: String
    $country: String
    $address: String
  ) {
    editBusinessPatient(
      userRoleId: $userRoleId
      patientInput: {
        firstName: $firstName
        lastName: $lastName
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        gender: $gender
        zipCode: $zipCode
        city: $city
        district: $district
        state: $state
        country: $country
    address: $address

      }
    )
  }
`
export const EDIT_INVENTOR_PRODCT = gql`
  mutation editInventoryProduct(
    $productDetails:purchaseOrderDetails!
    
  ) {
    editInventoryProduct(
      productDetails:$productDetails
    )
  }
`


export const GET_DOCTOR_LIST = gql`
  query getBusinessStaffList($role: String!) {
    getBusinessStaffList(role: $role) {
      userId
      userRoleId
      firstName
      lastName
      role
      profilePhoto
      isRegistered
      updatedDate
    }
  }
`
